import { ReactNode } from 'react';

import WidgetContact from './WidgetContact';
import WidgetExternalApplications, { WidgetExternalApplicationsConfiguration } from './WidgetExternalApplications';
import WidgetHtml, { WidgetHtmlConfiguration } from './WidgetHtml';
import modules from '../../modules/modules';
import { ModuleCodeEnum, WidgetTemplateType } from '../../types/widget';
import { WidgetEnum } from '../../utils/Widget';

const generalWidgets: WidgetTemplateType[] = [
	{
		component: WidgetExternalApplications,
		configurationComponent: WidgetExternalApplicationsConfiguration,
		name: 'Applications externes',
		type: WidgetEnum.EXTERNAL_APPLICATIONS,
		minW: 1,
		maxW: Infinity,
		minH: 1,
		maxH: 1,
		moduleCode: ModuleCodeEnum.GENERAL,
	},

	{
		component: WidgetHtml,
		configurationComponent: WidgetHtmlConfiguration,
		name: 'HTML',
		type: WidgetEnum.HTML,
		minW: 1,
		maxW: Infinity,
		minH: 1,
		maxH: Infinity,
		moduleCode: ModuleCodeEnum.GENERAL,
	},
	{
		component: WidgetContact,
		name: 'Contact',
		type: WidgetEnum.CONTACT,
		minW: 1,
		maxW: 1,
		minH: 2,
		maxH: 2,
		moduleCode: ModuleCodeEnum.GENERAL,
	},
];

export const widgetsByModule: {
	module: { code: string; name: string; icon: ReactNode };
	widgets: WidgetTemplateType[];
}[] = [
	{
		module: { code: 'general', name: 'Général', icon: null },
		widgets: generalWidgets,
	},
	...modules.flatMap(({ code, name, icon, widgets: moduleWidgets }) =>
		moduleWidgets
			? [
					{
						module: { code, name, icon },
						widgets: moduleWidgets.sort((a, b) => a.name.localeCompare(b.name)),
					},
			  ]
			: [],
	),
];

export const getWidgetTemplateByType = (type: WidgetEnum): WidgetTemplateType => {
	const widgetTemplates = [...generalWidgets, ...modules.flatMap(({ widgets: moduleWidgets }) => moduleWidgets ?? [])];
	const widgetTemplate = widgetTemplates.find((template) => template.type === type);
	if (!widgetTemplate) {
		throw new Error(`Unsupported widget type ${type}`);
	}
	return widgetTemplate;
};
