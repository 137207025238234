import { WidgetEnum } from '../utils/Widget';

export enum ModuleCodeEnum {
	GENERAL = 'general',
	ACCOUNTING = 'accounting',
	DOCUMENT = 'document',
	QUICKENTRY = 'quickentry',
	SIGN = 'sign',
	BROCHURE = 'brochure',
}

export type WidgetType = {
	id?: string;
	configuration?: string;
	height: number;
	name: string;
	type: WidgetEnum;
	width: number;
	x: number;
	y: number;
	moduleId?: string;
	moduleCode: ModuleCodeEnum;
};

export type WidgetComponentProps<T = object> = {
	widgetWithKey: WidgetWithKeyType;
	readOnly: boolean;
	configuration?: T;
};

export type WidgetWithKeyType = { key: string; widget: WidgetType };

export type WidgetConfigurationComponentProps = {
	widgetWithKey: WidgetWithKeyType;
	name?: string;
	configuration?: object;
	onSave: (name: string, configuration?: object) => Promise<void>;
	onBack: () => void;
};

export type WidgetTemplateType = {
	component: (props: WidgetComponentProps) => JSX.Element;
	configurationComponent?: (props: WidgetConfigurationComponentProps) => JSX.Element;
	maxH: number;
	maxW: number;
	minH: number;
	minW: number;
	name: WidgetType['name'];
	type: WidgetType['type'];
	moduleCode: WidgetType['moduleCode'];
};

export type WidgetExternalApplicationType = {
	key: string;
	name: string;
	type?: string;
	isExternal?: boolean;
	isInternal?: boolean;
	specific?: string;
};

export type WidgetExternalApplicationsConfigurationType = {
	applications?: WidgetExternalApplicationType[];
};
