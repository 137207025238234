import DocumentLastUploads from './DocumentLastUploadsWidget';
import DocumentUploadWidget from './WidgetDocumentUpload';
import { ModuleCodeEnum, WidgetTemplateType } from '../../../types/widget';
import { WidgetEnum } from '../../../utils/Widget';

const widgets: WidgetTemplateType[] = [
	{
		component: DocumentUploadWidget,
		name: 'Dépôt de documents',
		type: WidgetEnum.DOCUMENT_UPLOAD,
		minW: 2,
		maxW: Infinity,
		minH: 1,
		maxH: 1,
		moduleCode: ModuleCodeEnum.DOCUMENT,
	},
	{
		component: DocumentLastUploads,
		name: 'Historique des documents',
		type: WidgetEnum.DOCUMENT_LAST_UPLOADS,
		minW: 2,
		maxW: 2,
		minH: 2,
		maxH: Infinity,
		moduleCode: ModuleCodeEnum.DOCUMENT,
	},
];

export default widgets;
