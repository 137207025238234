import WidgetRevenueConfiguration from './WidgetRevenue/configuration/WidgetRevenueConfiguration';
import WidgetRevenue from './WidgetRevenue/WidgetRevenue';
import { ModuleCodeEnum, WidgetTemplateType } from '../../../types/widget';
import { WidgetEnum } from '../../../utils/Widget';

const widgets: WidgetTemplateType[] = [
	{
		component: WidgetRevenue,
		configurationComponent: WidgetRevenueConfiguration,
		name: 'Graphique',
		type: WidgetEnum.CHART,
		minW: 2,
		maxW: Infinity,
		minH: 2,
		maxH: Infinity,
		moduleCode: ModuleCodeEnum.ACCOUNTING,
	},
];

export default widgets;
