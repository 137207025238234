import { gql } from '@apollo/client';

import { USER_PERMISSION_FIELDS, USER_PERMISSION_MODULE_FIELDS } from './permission';
import { CustomerFileType } from '../../types/customerFile';
import { InternalUserType } from '../types/user';

export const INTERNAL_USER_BASE_FIELDS = `
	id
	email
	lastName
	firstName
	group {
		isAdministrator
		isDefault
		id
		name
	}
	hasUserPermissions
`;

export const INTERNAL_USER_FIELDS = `
	${INTERNAL_USER_BASE_FIELDS}
	permissionModules {
		${USER_PERMISSION_MODULE_FIELDS}
		permissions {
			${USER_PERMISSION_FIELDS}
		}
	}
`;

// QUERIES

export type FetchInternalUserType = {
	internalUser: InternalUserType;
};

export const FETCH_INTERNAL_USER = gql`
	query InternalUser($internalUserId: ID!) {
		internalUser(id: $internalUserId) {
			${INTERNAL_USER_FIELDS}
		}
	}
`;

export type FetchInternalUsersType = {
	internalUsers: {
		items: InternalUserType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_INTERNAL_USERS = gql`
	query InternalUsers($page: Page!, $orderBy: OrderBy, $search: String) {
		internalUsers(page: $page, orderBy: $orderBy, search: $search) {
			count
			items {
				${INTERNAL_USER_BASE_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchInternalUsersByGroupType = {
	internalUsersByGroup: {
		items: InternalUserType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_INTERNAL_USERS_BY_GROUP = gql`
	query InternalUsersByGroup($groupId: ID!, $page: Page!, $orderBy: OrderBy, $search: String) {
		internalUsersByGroup(groupId: $groupId, page: $page, orderBy: $orderBy, search: $search) {
			count
			items {
				id
				email
				lastName
				firstName
			}
			limit
			offset
		}
	}
`;

export type FetchInternalUsersWithoutCustomerFileRelationType = {
	internalUsersWithoutCustomerFileRelation: {
		count: number;
		items: InternalUserType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_INTERNAL_USERS_WITHOUT_CUSTOMER_FILE_RELATION = gql`
	query InternalUsersWithoutCustomerFileRelation($customerFileId: ID!, $page: Page!, $search: String) {
		internalUsersWithoutCustomerFileRelation(customerFileId: $customerFileId, page: $page, search: $search) {
			count
			items {
				${INTERNAL_USER_BASE_FIELDS}
			}
			limit
			offset
		}
	}
`;

// MUTATIONS

export type CreateInternalUserWithCustomerFilesType = {
	createInternalUserWithCustomerFiles: {
		customerFileIds: CustomerFileType['id'][];
		internalUser: InternalUserType;
	};
};

export const CREATE_INTERNAL_USER_WITH_CUSTOMER_FILES = gql`
	mutation CreateInternalUserWithCustomerFiles(
		$createInternalUserWithCustomerFilesInput: CreateInternalUserWithCustomerFilesInput!
	) {
		createInternalUserWithCustomerFiles(
			createInternalUserWithCustomerFilesInput: $createInternalUserWithCustomerFilesInput
		) {
			customerFileIds
			internalUser {
				${INTERNAL_USER_FIELDS}
			}
		}
	}
`;

export type UpdateInternalUserType = {
	updateInternalUser: InternalUserType;
};

export const UPDATE_INTERNAL_USER = gql`
	mutation UpdateInternalUser($updateInternalUserInput: UpdateInternalUserInput!) {
		updateInternalUser(updateInternalUserInput: $updateInternalUserInput) {
			${INTERNAL_USER_FIELDS}
		}
	}
`;

export type RemoveInternalUserType = {
	removeInternalUser: {
		id: InternalUserType['id'];
	};
};

export const REMOVE_INTERNAL_USER = gql`
	mutation RemoveInternalUser($removeInternalUserId: ID!) {
		removeInternalUser(id: $removeInternalUserId) {
			id
		}
	}
`;
